export const COLUMNS_TABLE = [
    {
        item: 'Name'
    },
    {
        item: 'Related Party'
    },
    {
        item: 'Description'
    },
    {
        item: 'State'
    },
    {
        item: 'More information'
    }
]
export const COLUMNS_TABLE = [
  {
    item: "Resource name",
    width: "",
  },
  {
    item: "Owner",
    width: "",
  },
  {
    item: "Type",
    width: "",
  },
  {
    item: "Description",
    width: "",
  },
  {
    item: "Status",
    width: "10%",
  },
  {
    icon: "circle",
    width: "5%",
  },
  {
    item: "Enable",
    width: "",
  },
];
<div class="container inbox-view">
  <ng-container *ngIf="!showDetail">
    <h1>Inbox management</h1>
    <span>List of requests generated by users to be managed by you.</span>
  </ng-container>

  <ng-container *ngIf="showDetail">
    <h1>View deployment</h1>
  </ng-container>

  <div class="cont-loader" *ngIf="loading && !showDetail; else content">
    <div class="loader"></div>
  </div>

  <ng-template #content>
    <div class="cont-inbox">
      <div class="card">
        <div class="card-header" *ngIf="!showDetail">
          <div class="result">Results: {{ inboxList.length }}</div>
          <div class="views">
            <div class="form-group">
              <label for="state" class="form-label">Filter</label>
              <select
                class="form-select form-control"
                aria-label="status request"
                (change)="selectStatus($event)"
              >
                <option
                  *ngFor="let option of filteringOptions"
                  [value]="option.id"
                >
                  {{ option.item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="resource-cont list-view" *ngIf="!showDetail">
            <app-view-list-deployments
              tipyPage="inbox"
              [routerNavigate]="routerNavigate"
              [columnsTable]="columnsTable"
              [deploymentList]="inboxList"
            ></app-view-list-deployments>
          </div>

          <div class="resource-approver-view" *ngIf="showDetail">
            <div class="approver-header">
              <div class="title">
                <h5>Deployment detail</h5>
                <span
                  class="material-symbols-outlined"
                  matTooltip="Refresh"
                  (click)="getDeployId(idDeployment)"
                >
                  refresh
                </span>
              </div>
              <div class="actions">
                <button class="back" (click)="back()">Back</button>
                <ng-container *ngIf="requestObject?.status === 'PENDING'">
                  <button class="deny" (click)="deny(requestObject?.id)">
                    Deny
                  </button>
                  <button class="approval" (click)="approve(requestObject?.id)">
                    Approval
                  </button>
                </ng-container>
              </div>
            </div>

            <div class="cont-loader" *ngIf="loadingDetail; else content">
              <div class="loader"></div>
            </div>

            <ng-template #content>
              <app-view-deployment-detail [deploymentDetail]="requestObject">
              </app-view-deployment-detail>
            </ng-template>
          </div>
        </div>

        <ng-container *ngIf="inboxList.length && !showDetail">
          <mat-paginator
            [length]="total"
            [pageSize]="8"
            [showFirstLastButtons]="true"
            [hidePageSize]="true"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

<!-- <div class="container inbox-view">
  <h1>Inbox management</h1>
  <span>List of requests generated by users to be managed by you.</span>

  <div class="cont-loader" *ngIf="loading && !showDetail; else content">
    <div class="loader"></div>
  </div>

  <ng-template #content>
    <div class="cont-inbox">
      <div class="card">
        <div class="card-header" *ngIf="!showDetail">
          <div class="result">Results: {{ inboxList.length }}</div>
          <div class="views">
            <div class="form-group">
              <label for="state" class="form-label">Filter</label>
              <select
                class="form-select form-control"
                aria-label="status request"
                (change)="selectStatus($event)"
              >
                <option
                  *ngFor="let option of filteringOptions"
                  [value]="option.id"
                >
                  {{ option.item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="resource-cont list-view" *ngIf="!showDetail">
            <table
              class="table table-hover"
              aria-describedby="list of request "
            >
              <thead>
                <tr>
                  <th *ngFor="let colum of columnsTable" scope="col">
                    {{ colum.item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let request of inboxList">
                  <td>
                    <a [href]="routerNavigate + request.id">
                      {{ request.name }}
                    </a>
                  </td>
                  <td>{{ request.relatedParty[0].name }}</td>
                  <td>{{ request.country }}</td>
                  <td>{{ request.request.requestedDay | date:'dd/MM/y' }}</td>
                  <td>{{ request.status | titlecase }}</td>
                  <td>
                    {{ request.configuration.linkedRequestedResourceName }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngTemplateOutlet="alert"></ng-container>
          </div>

          <div class="resource-approver-view" *ngIf="showDetail">
            <div class="approver-header">
              <h5><b>Deployment name: </b>{{ requestObject?.name }}</h5>
              <div class="actions">
                <button class="back" (click)="back()">Back</button>
                <button
                  class="deny"
                  (click)="deny(requestObject?.id)"
                  *ngIf="requestObject?.status === 'PENDING'"
                >
                  Deny
                </button>
                <button
                  class="approval"
                  (click)="approve(requestObject?.id)"
                  *ngIf="requestObject?.status === 'PENDING'"
                >
                  Approval
                </button>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label for="name" class="form-label">Status</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.status | titlecase }}
                  </div>
                </div>
              </div>

              <div class="col">
                <ng-container *ngFor="let user of requestObject?.relatedParty">
                  <ng-container *ngIf="user.role === 'REQUESTER'">
                    <div class="form-group">
                      <label for="name" class="form-label">Applicant</label>
                      <div class="input-group">
                        <div class="form-control">
                          {{ user?.name }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="col">
                <ng-container *ngFor="let user of requestObject?.relatedParty">
                  <ng-container *ngIf="user.role === 'APPROVER'">
                    <div class="form-group">
                      <label for="name" class="form-label">Approver</label>
                      <div class="input-group">
                        <div class="form-control">
                          {{ user?.name }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="col">
                <label for="name" class="form-label">Requested date</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ formatDateTime(requestObject?.request?.requestedDay) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label for="name" class="form-label">Cost center</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.costCenter }}
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="name" class="form-label">Deployment date</label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      formatDateTime(
                        requestObject?.scheduling?.actions[0]?.date
                      )
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col"
                *ngIf="requestObject?.scheduling?.type === 'EPHEMERAL'"
              >
                <label for="name" class="form-label">{{
                  requestObject?.scheduling?.type === "EPHEMERAL"
                    ? "Date of destruction"
                    : "Is ephemeral?"
                }}</label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.scheduling?.type === "EPHEMERAL"
                        ? formatDateTime(
                            requestObject?.scheduling?.actions[1]?.date
                          )
                        : "false"
                    }}
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="name" class="form-label">Cloud provider</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.configuration?.cloudProvider }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label for="name" class="form-label"
                  >Repository source branch
                </label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.deployOrchestrator
                        ?.repositorySourceBranchRef
                    }}
                  </div>
                </div>
              </div>
              <div class="col">
                <label for="name" class="form-label"
                  >Repository source kind</label
                >
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.deployOrchestrator
                        ?.repositorySourceKind
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col"
                *ngIf="
                  requestObject?.configuration?.deployOrchestrator
                    ?.repositoryTargetBranchRef
                "
              >
                <label for="name" class="form-label"
                  >Repository target branch</label
                >
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.deployOrchestrator
                        ?.repositoryTargetBranchRef
                    }}
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="requestObject?.configuration?.gitHubUser">
                <label for="name" class="form-label">GitHub user</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.configuration?.gitHubUser }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label for="name" class="form-label">Resource</label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.linkedRequestedResourceName
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="requestObject?.configuration?.inputs.length"
            >
              <div class="col">
                <h5><b>Parameters</b></h5>
                <table class="table" aria-describedby="list parameters">
                  <thead>
                    <tr>
                      <th scope="col">Key</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let param of requestObject?.configuration?.inputs"
                    >
                      <td>{{ param.key }}</td>
                      <td>{{ param.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row" class="logs" *ngIf="requestObject?.events">
              <h5><b>Logs</b></h5>

              <div class="event">
                <span><b>Events</b></span>
                <div class="logs-container">
                  <div class="log" *ngFor="let event of requestObject?.events">
                    <div class="status">
                      <p
                        [ngClass]="
                          event.type === 'WARNING'
                            ? 'warning'
                            : event.type === 'INFO'
                            ? 'info'
                            : event.type === 'ERROR'
                            ? 'error'
                            : event.type === 'SUCCESS'
                            ? 'success'
                            : ''
                        "
                      >
                        {{ event.type }}
                      </p>
                    </div>
                    <div class="message">
                      <p>{{ event.message }}</p>
                      <p>
                        {{ formatDateTime("2024-05-28T13:20:29.785Z") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="output" *ngIf="requestObject?.outputs?.length">
                <span><b>Outputs</b></span>
                <div class="logs-container">
                  <div class="log" *ngFor="let output of requestObject?.outputs">
                    <div class="status">
                      <p>
                        {{ output.key }}
                      </p>
                    </div>
                    <div class="message">
                      <p class="log-item">
                        {{ output.value }}
                      </p>
                      <p>
                        {{ output.type }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="inboxList.length">
          <mat-paginator
            [length]="10"
            [pageSize]="8"
            [showFirstLastButtons]="true"
            [hidePageSize]="true"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </ng-container>

      </div>
    </div>
  </ng-template>
</div>

<ng-template #alert>
  <div class="style-alert" *ngIf="!inboxList.length">No data available!</div>
</ng-template> -->

<div class="nav">
  <input type="checkbox" id="nav-check" />
  <div class="nav-header">
    <div class="nav-title">
      <img
        class="logo"
        src="{{ theme?.properties?.logo }}"
        alt="logo-company"
      />
    </div>
  </div>

  <div class="nav-links">
    <a target="_blank" class="align-middle" (click)="iniciarSesion()">Login</a>
  </div>

  <div class="login-form" *ngIf="localLogin">
    <button class="close-button" (click)="closeForm()">X</button>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="mb-3">
        <label for="inputEmail1" class="form-label">User</label>
        <input
          type="email"
          class="form-control"
          id="inputEmail1"
          formControlName="email"
          aria-describedby="emailHelp"
        />
      </div>
      <div class="mb-3">
        <label for="inputPassword1" class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          id="inputPassword1"
          formControlName="password"
        />
      </div>
      <button type="submit" class="btn btn-primary">Sign in</button>
    </form>
  </div>
</div>
<div class="content">
  <div class="main-content">
    <div class="containerP">
      <p class="text-home">provisioning portal</p>
    </div>

    <img
      src="../../../assets/res/home-page/standard-quality-control-collage-concept.jpg"
      class="img-home"
      alt="stuff"
    />
  </div>
  <div class="secondary-content">
    <div class="card-world">
      <p>
        Welcome to the <strong>provisioning portal</strong>, an online platform
        designed to help to plan, execute, and evaluate proofs of concept (PoC)
        efficiently and effectively. On this portal, users can access a wide
        range of tools and resources to create and manage PoCs more effectively.
        Overall, the Sandox Commercial Portal is an invaluable tool that seeks
        to streamline your proof of concept process and accelerate the launch of
        new products or services to the market.
      </p>
    </div>
  </div>
  <div class="opcional-content" *ngIf="theme.id === 'default'">
    <app-carrousel></app-carrousel>
  </div>
</div>



export const environment = {
  production: false,
  ACCOUNT_NAME: 'angularblobstorage',
  SAS: 'FE8AH9TN0QpATuxY40syANu+pCHTvtlobqCLdnHzZp57t8OwJHbKq5Rfu1oNmNRWr1l+60R4OxMS+AStyh0EnA==',
  API_URL:"https://provisioning-app.redflower-4e1a32a4.eastus2.azurecontainerapps.io/api/",
  adminCredentials: {
    email: 'adminportal',
    password: 'G3nE!7uZ^QpW8lR'
  }
};



<div class="container request-view">
  <ng-container *ngIf="!showDetail">
    <h1>Request management</h1>
    <span>List of requests generated by you and your respective approvers.</span>
  </ng-container>

  <ng-container *ngIf="showDetail">
    <h1>View deployment</h1>
  </ng-container>

  <div class="cont-loader" *ngIf="loading && !showDetail; else content">
    <div class="loader"></div>
  </div>

  <ng-template #content>
    <div class="cont-request">
      <div class="card">
        <div class="card-header" *ngIf="!showDetail">
          <div class="result">Results: {{ requestList.length }}</div>
          <div class="views">
            <div class="form-group">
              <label for="state" class="form-label">Filter</label>
              <select
                class="form-select form-control"
                (change)="selectStatus($event)"
                aria-label="status request"
              >
                <option
                  *ngFor="let option of filteringOptions"
                  [value]="option.id"
                >
                  {{ option.item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="resource-cont list-view" *ngIf="!showDetail">
            <app-view-list-deployments
              tipyPage="request"
              [routerNavigate]="routerNavigate"
              [columnsTable]="columnsTable"
              [deploymentList]="requestList"
            ></app-view-list-deployments>
          </div>

          <div class="resource-view" *ngIf="showDetail">
            <div class="approver-header">
              <div class="title">
                <h5>Deployment detail</h5>
                <span
                  class="material-symbols-outlined"
                  matTooltip="Refresh"
                  (click)="getDeployId(idDeploy)"
                >
                  refresh
                </span>
              </div>
              <div class="actions">
                <button class="back" (click)="back()">Back</button>
              </div>
            </div>

            <div class="cont-loader" *ngIf="loadingDetail; else content">
              <div class="loader"></div>
            </div>

            <ng-template #content>
              <app-view-deployment-detail [deploymentDetail]="requestObject">
              </app-view-deployment-detail>
            </ng-template>
          </div>
        </div>

        <ng-container *ngIf="requestList.length">
          <mat-paginator
            [length]="total"
            [pageSize]="8"
            [showFirstLastButtons]="true"
            [hidePageSize]="true"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

<!-- <div class="container request-view">
  <h1>Request management</h1>
  <span>List of requests generated by you and your respective approvers.</span>

  <div class="cont-loader" *ngIf="loading && !showDetail; else content">
    <div class="loader"></div>
  </div>

  <ng-template #content>
    <div class="cont-request">
      <div class="card">
        <div class="card-header" *ngIf="!showDetail">
          <div class="result">Results: {{ requestList.length }}</div>
          <div class="views">
            <div class="form-group">
              <label for="state" class="form-label">Filter</label>
              <select
                class="form-select form-control"
                (change)="selectStatus($event)"
                aria-label="status request"
              >
                <option
                  *ngFor="let option of filteringOptions"
                  [value]="option.id"
                >
                  {{ option.item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="resource-cont list-view" *ngIf="!showDetail">
            <table
              class="table table-hover"
              aria-describedby="list of request "
            >
              <thead>
                <tr>
                  <th *ngFor="let colum of columnsTable" scope="col">
                    {{ colum.item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let request of requestList">
                  <td>
                    <a [href]="routerNavigate + request.id">
                      {{ request.name }}
                    </a>
                  </td>
                  <td>{{ request.relatedParty[1].name }}</td>
                  <td>{{ request.country }}</td>
                  <td>{{ request.request.requestedDay | date : "dd/MM/y" }}</td>
                  <td>{{ request.status | titlecase }}</td>
                  <td>
                    {{ request.configuration.linkedRequestedResourceName }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngTemplateOutlet="alert"></ng-container>
          </div>

          <div class="resource-view" *ngIf="showDetail">
            <div class="approver-header">
              <h5><b>Deployment name: </b>{{ requestObject?.name }}</h5>
              <div class="actions">
                <button class="back" (click)="back()">back</button>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label class="form-label" for="name">Status</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.status | titlecase }}
                  </div>
                </div>
              </div>

              <div class="col">
                <ng-container *ngFor="let user of requestObject?.relatedParty">
                  <ng-container *ngIf="user.role === 'REQUESTER'">
                    <div class="form-group">
                      <label class="form-label" for="name">Applicant</label>
                      <div class="input-group">
                        <div class="form-control">
                          {{ user?.name }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="col">
                <ng-container *ngFor="let user of requestObject?.relatedParty">
                  <ng-container *ngIf="user.role === 'APPROVER'">
                    <div class="form-group">
                      <label class="form-label" for="name">Approver</label>
                      <div class="input-group">
                        <div class="form-control">
                          {{ user?.name }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div class="col">
                <label class="form-label" for="name">Requested date</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ formatDateTime(requestObject?.request?.requestedDay) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label class="form-label" for="name">Cost center</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.costCenter }}
                  </div>
                </div>
              </div>

              <div class="col">
                <label class="form-label" for="name">Deployment date</label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      formatDateTime(
                        requestObject?.scheduling?.actions[0]?.date
                      )
                    }}
                  </div>
                </div>
              </div>
              <div
                class="col"
                *ngIf="requestObject?.scheduling?.type === 'EPHEMERAL'"
              >
                <label class="form-label" for="name">{{
                  requestObject?.scheduling?.type === "EPHEMERAL"
                    ? "Date of destruction"
                    : "Is ephemeral?"
                }}</label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.scheduling?.type === "EPHEMERAL"
                        ? formatDateTime(
                            requestObject?.scheduling?.actions[1]?.date
                          )
                        : "false"
                    }}
                  </div>
                </div>
              </div>

              <div class="col">
                <label class="form-label" for="name">Cloud provider</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.configuration?.cloudProvider }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label for="name" class="form-label"
                  >Repository source kind</label
                >
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.deployOrchestrator
                        ?.repositorySourceKind
                    }}
                  </div>
                </div>
              </div>

              <div class="col">
                <label class="form-label" for="name"
                  >Repository source branch
                </label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.deployOrchestrator
                        ?.repositorySourceBranchRef
                    }}
                  </div>
                </div>
              </div>

              <div
                class="col"
                *ngIf="
                  requestObject?.configuration?.deployOrchestrator
                    ?.repositoryTargetBranchRef
                "
              >
                <label class="form-label" for="name"
                  >Repository target branch</label
                >
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.deployOrchestrator
                        ?.repositoryTargetBranchRef
                    }}
                  </div>
                </div>
              </div>
              <div class="col"  *ngIf="requestObject?.configuration?.gitHubUser">
                <label class="form-label" for="name">GitHub user</label>
                <div class="input-group">
                  <div class="form-control">
                    {{ requestObject?.configuration?.gitHubUser }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row information">
              <div class="col">
                <label for="name" class="form-label">Resource</label>
                <div class="input-group">
                  <div class="form-control">
                    {{
                      requestObject?.configuration?.linkedRequestedResourceName
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              *ngIf="requestObject?.configuration?.inputs.length"
            >
              <div class="col">
                <h5><b>Parameters</b></h5>
                <table aria-describedby="list parameters" class="table">
                  <thead>
                    <tr>
                      <th scope="col">Key</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let params of requestObject?.configuration?.inputs
                      "
                    >
                      <td>{{ params.key }}</td>
                      <td>{{ params.value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div *ngIf="requestObject?.events" class="row" class="logs">
              <h5><b>Logs</b></h5>
              <div class="event">
                <span><b>Events</b></span>
                <div class="logs-container">
                  <div class="log" *ngFor="let event of requestObject?.events">
                    <div class="status">
                      <p
                        [ngClass]="
                          event.type === 'ERROR'
                            ? 'error'
                            : event.type === 'WARNING'
                            ? 'warning'
                            : event.type === 'INFO'
                            ? 'info'
                            : event.type === 'SUCCESS'
                            ? 'success'
                            : ''
                        "
                      >
                        {{ event.type }}
                      </p>
                    </div>
                    <div class="message">
                      <p>{{ event.message }}</p>
                      <p>
                        {{ formatDateTime(event.date) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="output" *ngIf="requestObject?.outputs?.length">
                <span><b>Outputs</b></span>
                <div class="logs-container">
                  <div
                    class="log"
                    *ngFor="let output of requestObject?.outputs"
                  >
                    <div class="status">
                      <p>
                        {{ output.key }}
                      </p>
                    </div>
                    <div class="message">
                      <p class="log-item">
                        {{ output.value }}
                      </p>
                      <p>
                        {{ output.type }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="requestList.length">
          <mat-paginator
            [length]="10"
            [pageSize]="8"
            [showFirstLastButtons]="true"
            [hidePageSize]="true"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #alert>
  <div class="style-alert" *ngIf="!requestList.length">No data available!</div>
</ng-template> -->
